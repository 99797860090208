define(['app', 'accessibilityFocusHelper'], (app, accessibilityFocusHelper) => {

  const productBlockRailSlider = () => {
    const DATA_NUMBER_PROD = 'data-number-prod';
    const component = {};

    component.config = {
      selectors: {
        leftArrow: '.productBlockRailSlider_arrowLeft',
        rightArrow: '.productBlockRailSlider_arrowRight',
        arrowContainer: '.productBlockRailSlider_arrowsContainer',
        itemContainer: '.productBlockRailSlider_itemContainer',
        navBullets: '.productBlockRailSlider_navBullets',
      },
      classes: {
        navBullet: 'productBlockRailSlider_navBullet',
        navBulletActive : 'productBlockRailSlider_navBullet-active'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.leftArrow = element.querySelector(component.config.selectors.leftArrow);
      component.rightArrow = element.querySelector(component.config.selectors.rightArrow);
      component.arrowContainer = element.querySelector(component.config.selectors.arrowContainer);
      component.itemContainer = element.querySelector(component.config.selectors.itemContainer);
      component.navBullets = element.querySelector(component.config.selectors.navBullets);
      component.numberOfProducts = parseInt(component.itemContainer.getAttribute(DATA_NUMBER_PROD));
      component.activeBulletIndex = 0;

      component.createNavBullets();
      component.setStateOfArrows();
      component.bind();
    };

    component.bind = () => {
      component.leftArrow.addEventListener('click', component.previousView)
      component.rightArrow.addEventListener('click', component.nextView)
    };

    component.setNavBulletActive = (indexBullet) => {
      // Check if the index is valid
      if (indexBullet >= component.numberOfBullets - 1) {
        indexBullet =  component.numberOfBullets - 1;
      } else if (indexBullet < 0)
        indexBullet = 0;

      // remove active class from current bullet
      component.navBullets.children[component.activeBulletIndex].classList.remove(component.config.classes.navBulletActive);

      component.activeBulletIndex = indexBullet;
      component.navBullets.children[indexBullet].classList.add(component.config.classes.navBulletActive);
    };

    component.createNavBullets = () => {
        if (component.numberOfProducts > 2) {
          component.numberOfBullets = Math.ceil(component.numberOfProducts /2);

          for (let index = 0; index < component.numberOfBullets; index++) {
            let navBullet = document.createElement("span");
            navBullet.classList.add(component.config.classes.navBullet);
            component.navBullets.appendChild(navBullet);
          }
          component.setNavBulletActive(0);
        } else {
          component.navBullets.style.display = 'none';
        }
    };

    component.setStateOfArrows = () => {
      if (component.numberOfProducts < 3) {
        component.arrowContainer.style.display = 'none';
      } else if (component.activeBulletIndex === 0) {
        component.leftArrow.disabled = true;
        component.rightArrow.disabled = false;
        accessibilityFocusHelper.focus(component.rightArrow);
      } else if (component.activeBulletIndex === component.numberOfBullets - 1) {
        component.leftArrow.disabled = false;
        component.rightArrow.disabled = true;
        accessibilityFocusHelper.focus(component.leftArrow);
      } else {
        component.leftArrow.disabled = false;
        component.leftArrow.disabled = false;
      }
    };

    component.smoothScrollingBy = (amount) => {
      let absoluteAmount = Math.abs(amount);
      const absoluteScrollIncrement = absoluteAmount / 30;
      let scrollIncrement = amount / 30;

      let id;
      const animateScrolling = () => {
        if (absoluteAmount <= 0) {
          clearInterval(id);
        } else {
          absoluteAmount -= absoluteScrollIncrement;
          component.itemContainer.scrollLeft += scrollIncrement;
        }
      };
      id = setInterval(animateScrolling, 10);
    };

    component.previousView = () => {
      let itemContainerWidth = component.itemContainer.getBoundingClientRect().width;
      component.setNavBulletActive(component.activeBulletIndex - 1);
      component.smoothScrollingBy(-itemContainerWidth);
      component.setStateOfArrows();
    };

    component.nextView =  () => {
      let itemContainerWidth = component.itemContainer.getBoundingClientRect().width;
      component.setNavBulletActive(component.activeBulletIndex + 1);
      component.smoothScrollingBy(itemContainerWidth);
      component.setStateOfArrows();
    };


    return component;
  };
  return productBlockRailSlider;
});
